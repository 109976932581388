<template>
    <v-container fluid>
      <v-row>
        <v-col class="col-12">
          <h1>Requires pro-forma invoice</h1>
          <v-simple-table
            fixed-header
            height="450px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">Customer</th>
                  <th class="text-left">Address</th>
                  <th class="text-left">Date</th>
                  <th class="text-left">Value</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="proforma in proformas"
                  :key="proforma.id"
                >
                  <td>{{ proforma.jobNumber }}</td>
                  <td>{{ proforma.customer }}</td>
                  <td>{{ proforma.site }}</td>
                  <td>{{ proforma.date | tinyDate }}</td>
                  <td>{{ proforma.value | priceInPounds | currency }}</td>
                  <td>
                    <router-link custom v-slot="{ navigate }" :to="`/proformas/progress/${proforma.id}`">
                      <v-btn
                        title="View"
                        x-small
                        @click="navigate"
                        @keypress.enter="navigate"
                        role="link">
                          View
                      </v-btn>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-pagination
            class="pt-4"
            v-model="page"
            :length="length"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :total-visible="totalVisible"
          ></v-pagination>
        </v-col>
      </v-row>
      <router-view></router-view>
    </v-container>
  </template>
  
  <script>
  import axios from '../../axios';
  
  export default {
    name: 'ProformasAwaitingIndex',
    computed: {
      token() {
        return this.$store.state.token;
      },
    },
    data() {
      return {
        proformas: [],
        page: 1,
        length: 0,
        totalVisible: 9,
      };
    },
    watch: {
      page() {
        this.getProformas();
      },
    },
    methods: {
      getProformas() {
        axios.get(`/proformas/getAwaiting/${this.page}.json?token=${this.token}`)
          .then((response) => {
            this.proformas = response.data.proformas;
            this.length = response.data.length;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted() {
      this.getProformas();
    },
  };
  </script>
  